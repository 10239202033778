import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const AlertTitle = makeShortcode("AlertTitle");
const Code = makeShortcode("Code");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Introduction to `}<inlineCode parentName="h2">{`step-ca`}</inlineCode></h2>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` is an online Certificate Authority (CA) for secure, automated X.509 and SSH certificate management.
It's the server counterpart to `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli"
      }}><inlineCode parentName="a">{`step`}</inlineCode>{` CLI`}</a>{`.
It is secured with TLS,
and it offers several configurable certificate provisioners, flexible certificate templating, and pluggable database backends to suit a wide variety of contexts and workflows.
It employs sane default algorithms and attributes,
so you don't have to be a security engineer to use it securely.`}</p>
    <p>{`Teams use `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to:`}</p>
    <ul>
      <li parentName="ul">{`Generate TLS certificates for private infrastructure using the ACME protocol`}</li>
      <li parentName="ul">{`Automate TLS certificate renewal`}</li>
      <li parentName="ul">{`Add Automated Certificate Management Environment (ACME) support to a legacy subordinate CA`}</li>
      <li parentName="ul">{`Issue short-lived SSH certificates via OAuth OIDC single sign on`}</li>
      <li parentName="ul">{`Issue customized X.509 and SSH certificates`}</li>
      <li parentName="ul">{`and much more ...`}</li>
    </ul>
    <Alert severity="success" mdxType="Alert">
  <AlertTitle mdxType="AlertTitle">Don&apos;t want to run your own CA?</AlertTitle>
  <div>
    To get up and running quickly, or as an alternative to running your own{' '}
    <Code mdxType="Code">step-ca</Code> server, consider creating a{' '}
    <Link external href="https://smallstep.com/signup?product=cm" mdxType="Link">
      free hosted smallstep Certificate Manager authority
    </Link>
    .
  </div>
    </Alert>
    <h2>{`Features`}</h2>
    <h3>{`X.509 Certificate Authority`}</h3>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` issues X.509 certificates for use with TLS, mutual TLS (mTLS) authentication, document signing, and X.509 authentication more broadly.
With `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, you can:`}</p>
    <ul>
      <li parentName="ul">{`Automate certificate issuance and renewal for clients and servers and Kubernetes workloads.`}</li>
      <li parentName="ul">{`Issue certificates to humans, eg. using Single Sign-on (OpenID Connect) for authentication.`}</li>
      <li parentName="ul">{`Choose key types (RSA, ECDSA, EdDSA) and lifetimes to suit your needs`}</li>
      <li parentName="ul">{`Issue `}<a parentName="li" {...{
          "href": "https://smallstep.com/blog/passive-revocation.html"
        }}>{`short-lived certificates`}</a>{` with automated enrollment, renewal, and passive revocation`}</li>
      <li parentName="ul">{`Operate `}<a parentName="li" {...{
          "href": "https://smallstep.com/docs/tutorials/intermediate-ca-new-ca"
        }}>{`an online intermediate CA`}</a>{` to an existing root CA, eg. to add support for ACME to an existing PKI`}</li>
      <li parentName="ul">{`Operate a local `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/registration-authority-ra-mode"
        }}>{`Registration Authority (RA)`}</a>{` on an internal network or inside a Kubernetes cluster, that authorizes certificate requests for an upstream `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` server.`}</li>
      <li parentName="ul">{`Deploy a high availability (HA) Certificate Authority using `}<a parentName="li" {...{
          "href": "https://smallstep.com/blog/step-v0.8.3-federation-root-rotation.html"
        }}>{`root federation`}</a>{` and/or multiple intermediaries`}</li>
    </ul>
    <h3>{`SSH Certificate Authority`}</h3>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` can issue SSH certificates to users and hosts.
Delegate SSH authentication to `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`
and set up a clear chain of trust for authorizing access.`}</p>
    <ul>
      <li parentName="ul">{`Avoid the risk of trust-on-first-use (TOFU) when connecting to SSH hosts`}</li>
      <li parentName="ul">{`Issue short-lived SSH user certificate via `}<a parentName="li" {...{
          "href": "https://smallstep.com/blog/diy-single-sign-on-for-ssh/"
        }}>{`Single Sign-on`}</a>{`.`}</li>
    </ul>
    <h3>{`Provisioners`}</h3>
    <p>{`Provisioners are methods of using the CA to get certificates for humans or machines.
They offer different modes of authorization for the CA.`}</p>
    <p>{`For example, you can have your CA issue certificates in exchange for:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/tutorials/acme-protocol-acme-clients"
        }}>{`ACME challenge responses`}</a>{` from any ACMEv2 client`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://smallstep.com/blog/easily-curl-services-secured-by-https-tls.html"
        }}>{`OAuth OIDC single sign-on tokens`}</a>{`, eg:`}
        <ul parentName="li">
          <li parentName="ul">{`ID tokens from Okta, G Suite, Azure AD, Auth0.`}</li>
          <li parentName="ul">{`ID tokens from an OAuth OIDC service that you host, like `}<a parentName="li" {...{
              "href": "https://www.keycloak.org/"
            }}>{`Keycloak`}</a>{` or `}<a parentName="li" {...{
              "href": "https://github.com/dexidp/dex"
            }}>{`Dex`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://smallstep.com/blog/embarrassingly-easy-certificates-on-aws-azure-gcp/"
        }}>{`Cloud instance identity documents`}</a>{`, for VMs on AWS, GCP, and Azure`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#jwk"
        }}>{`Single-use, short-lived JWK tokens`}</a>{`, eg. issued by your CD tool — Puppet, Chef, Ansible, Terraform, etc.`}</li>
    </ul>
    <h3>{`Templates`}</h3>
    <p><a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/templates#x509-templates"
      }}>{`X.509 Templates`}</a>{` let you customize certificate fields, eg:`}</p>
    <ul>
      <li parentName="ul">{`Add custom Subject Alternative Names (SANs) or non-standard X.509 Object Identifiers (OIDs) to certificates`}</li>
      <li parentName="ul">{`Restrict certificates by domain name or key size`}</li>
      <li parentName="ul">{`Issue CA certificates with longer path lengths for multiple intermediaries`}</li>
    </ul>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` ships with several built-in templates for everyday operations,
and you can use Golang's `}<inlineCode parentName="p">{`text/template`}</inlineCode>{` syntax to create new templates.`}</p>
    <h3>{`Cryptographic Protection`}</h3>
    <p>{`For strong protection of your CA signing keys, we've built `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/configuration#cryptographic-protection"
      }}><inlineCode parentName="a">{`step-ca`}</inlineCode>{` integrations`}</a>{` for PKCS #11 HSMs, Google Cloud KMS, AWS KMS, and YubiKey PIV, among others.`}</p>
    <h3>{`Other Integrations`}</h3>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` plays well with Kubernetes cert-manager and Envoy Secret Discovery Service. See `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/integrations"
      }}>{`Integrations`}</a>{` to learn more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      